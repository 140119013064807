<template>
    <div :class="CSSClasses" class="content-summary">
        <EventDateCard v-if="dateTime && !isPastEvent && !isDateCardHidden" :eventDate="dateTime" />
        <div class="summary-wrapper">
            <div class="meta">
                <TypeLabel
                    v-if="!isLabelHidden && label"
                    :name="label"
                    :pageLanguage="pageLanguage"
                    class="type-label"
                />

                <div class="meta-right" v-if="attributions">
                    <slot name="meta-right">
                        <AttributionLabel :attributions="attributions" :pageLanguage="pageLanguage" variant="contentCard" />
                        <AttributionLabel
                            v-if="attributionsFromSyndications"
                            :attributions="attributionsFromSyndications"
                            :pageLanguage="pageLanguage"
                            variant="contentCard"
                        />
                    </slot>
                </div>
            </div>

            <Anchor
                :url="mediaSource?.url ? mediaSource.url : translationFriendlyUrl"
                :type="mediaSource?.url ? 'external' : ''"
            >
                <div class="heading">
                    <slot name="title">
                        <Typography v-if="title" as="div" variant="body-large">
                            {{ title }}
                        </Typography>
                    </slot>
                </div>
            </Anchor>
            <div v-if="dateTime" class="date-time">
                <div class="date" v-if="isPastEvent || isDateCardHidden || futureEvent">
                    <Icon name="calendar" />
                    <Typography variant="body">{{ formatDateIntnl(dateTime.start, pageLanguage) }}</Typography>
                </div>
                <div class="time" v-if="!isPastEvent">
                    <Typography variant="body">
                        {{ formatTime(dateTime.start) }} — {{ formatTime(dateTime.end) }}
                        {{ dateTime.timeZone }}
                    </Typography>
                </div>
            </div>
            <div class="description">
                <slot v-if="!isDescriptionHidden" name="description">
                    <RichTextElements v-if="description" bodyStyle="serif-large" :elements="description" />
                </slot>
            </div>
            <div class="last-row credits">
                <AvatarSet v-if="!isPeopleHidden && people?.length" :people="people" :theme="theme" :pageLanguage="pageLanguage"></AvatarSet>
                <Typography
                    v-if="showPublicationDate && publishedAt && !dateTime"
                    variant="body-micro"
                    class="publication-date"
                    >· {{ publicationDate }}</Typography
                >
                <RichTextElements
                    v-if="sourceLabel"
                    :html="sourceLabel"
                    bodyStyle="body-micro-italic"
                    class="source-label"
                />
                <RichTextElements
                    v-else-if="publisherName"
                    :html="publisherName"
                    bodyStyle="body-micro-italic"
                    class="source-label"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    title: {
        type: String,
        default: '',
    },
    url: {
        type: String,
        default: '',
    },
    description: {
        type: Object,
        default: () => {},
    },
    people: {
        type: Array,
        default: () => [],
    },
    isLabelHidden: {
        type: Boolean,
        default: false,
    },
    isDescriptionHidden: {
        type: Boolean,
        default: false,
    },
    isPeopleHidden: {
        type: Boolean,
        default: false,
    },
    isDateCardHidden: {
        type: Boolean,
        default: false,
    },
    mobileHideDescription: {
        type: Boolean,
        default: false,
    },
    showPublicationDate: {
        type: Boolean,
        default: false,
    },
    mediaSource: {
        type: Object,
        default: () => {},
    },
    publisherName: {
        type: String,
        default: '',
    },
    dateTime: {
        type: Object,
        default: () => {},
    },
    futureEvent: {
        type: Boolean,
        default: false,
    },
    theme: {
        ...themes,
    },
    attributions: {
        type: [Array, Object],
    },
    syndications: {
        type: Object,
    },
    publishedAt: {
        type: String,
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
});

const CSSClasses = computed(() => {
    return [props.mobileHideDescription && 'mobile-hide-description', `theme-${props.theme}`];
});

const isPastEvent = computed(() => {
    return dateRelation(props.dateTime?.end) === 'past';
});

const publicationDate = computed(() => {
    return formatDateIntnl(props.publishedAt, props.pageLanguage);
});

const translationFriendlyUrl = computed(() => {
    return props.pageLanguage.length ? props.url + '?lang=' + props.pageLanguage : props.url;
});

const sourceLabel = computed(() => {
    return props.mediaSource?.label || props.publisherName;
});

const attributionsFromSyndications = computed(() => {
    return useAttribution(props.syndications);
});
</script>

<style lang="scss" scoped>
.content-summary {
    display: flex;
    gap: var(--col-gutter);
}

.summary-wrapper {
    max-width: 100%;
}

.event-date-card + .summary-wrapper {
    max-width: calc(100% - 8rem);
}

.meta {
    display: flex;
    gap: 1.2rem;
    @include media-query(mobile) {
        display: block;
        .meta-right {
            margin-bottom: 0.8rem;
        }
    }
}

.type-label {
    margin-bottom: 0.8rem;
}

.heading {
    margin-bottom: calc(1em * 8 / 28);

    .is-description-light & {
        color: color(blanc);
    }
}

.description {
    .mobile-hide-description & {
        @include media-query(mobile) {
            display: none;
        }
    }
}

.credits {
    margin-top: 0.4rem;
}

.date-time {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.4rem 0;

    .date {
        color: color(blue);
        display: flex;
        gap: 0.4rem;
        align-items: center;

        span {
            color: color(offnoir);
        }
    }

    .time {
        color: color(offnoir);
        opacity: 0.6;
        display: flex;
    }

    .is-description-light & .date,
    .is-description-light & .time {
        color: color(blanc, default, 0.8);
    }

    @include media-query(mobile) {
        display: block;
        .date,
        .time {
            display: block;
        }
        .date svg {
            display: none;
        }
    }
}

.last-row {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    max-width: 100%;
    @include media-query(mobile) {
        display: block;
        :deep(.avatars) {
            display: none;
        }
    }
}

.publication-date,
.source-label {
    color: color(offnoir, default, 0.6);
    position: relative;
    top: 0.15rem;
}

.publication-date {
    white-space: nowrap;
    top: 0.15rem;
}

.content-summary.theme-dark {
    color: color(blanc);

    .description,
    .publication-date,
    .source {
        color: color(blanc, default, 0.6);
    }
}
</style>
