<template>
    <div class="event-date-card">
        <div class="month">
            <Typography variant="body-micro" transform="uppercase">{{
                $t(parseDate(eventDate.start).month, 1, { locale: pageLanguage })
            }}</Typography>
        </div>
        <div class="day">
            <Typography variant="h2">{{ parseDate(eventDate.start).day }}</Typography>
        </div>
        <div class="year">
            <Typography variant="body-micro">{{ parseDate(eventDate.start).year }}</Typography>
        </div>
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    eventDate: {
        type: Object,
    },
});
</script>

<style lang="scss" scoped>
.event-date-card {
    border: 0.08rem solid rgba(0, 39.06, 68, 0.2);
    border-radius: 0.28rem;
    background: color(blanc);
    overflow: hidden;
    width: 8rem;
    flex-shrink: 0;
    text-align: center;
    align-self: start;

    .month {
        width: 8rem;
        padding: 0.2rem 0.7rem 0.3rem;
        background: color(blue);
        color: color(blanc, default, 0.8);
    }

    .day {
        color: color(teal);
    }

    .year {
        color: color(grey, dark, 0.6);
    }
}
</style>
